.navBar{
  height: 8vh;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  position: fixed;
  background-color: #F6F8EA;
  width: 100%;
  z-index: 4;
}

.barNav{
  display: none;
}

.imgDiv{
  padding: 2vw;
}

.imgDiv img{
  height: 9vh;
}

.itemDiv{
  width: 90%;
  display: flex;
  justify-content: space-around;
  padding: 0 1vw;
}

.linkItem{
  font-weight: bold;
  font-size: 1.5em;
  color: #003D6F;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width: 1200px) {
  .itemDiv{
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 8vh;
    background-color: #f0f0e6;
    width: 100%;
    left: 0%;
    padding: 2vh;
    /* background-color: #003D6F; */
    z-index: 50;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    border-top: 1px solid #000;
  }
  .itemDiv.show {
    max-height: fit-content; 
    opacity: 1;
  }
  .linkItem{
    color: #003D6F;
    padding: 2vh 0;
  }
  .barNav{
    cursor: pointer;
    display: flex;
    position: absolute;
    right: 4%;
  }
  .navBar{
    height: 8%;
  }
}