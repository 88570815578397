.parentDiv{
  width: 83%;
  height: 100%;
  overflow-y: scroll;
}

.parentDiv h2{
  margin-left: 2vh;
}

.tableDiv{
  width: 96%;
}

table{
  /* width: 100%; */
  margin: 2vh 2vh;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

th {
  background-color: #666666;
  color: white;
}
th, td {
  /* background-color: #96D4D4; */
  padding: 1vh;
}

.imageDiv{
  display: flex;
  width: 40%;
  background-color: #666666;
}

.imageDiv img{
  width: 100%;
}

.backDrop{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 20;
}

.modalDiv{
  border: 1px solid black;
  margin: auto 20%;
  top: 30vh;
  left: 40vh;
  width: 30%;
  height: 20%;
  border-radius: 10px;
  position: fixed;
  z-index: 30;
  background-color: #fff;
}

.textDiv{
  /* height: 60%; */
  /* background-color: aqua; */
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  padding: 3vh;
}

.btnDiv{
  display: flex;
  margin: 0 1vh;
  justify-content: space-around;
}