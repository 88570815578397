.inputDiv{
  display: flex;
  flex-direction: column;
}

.inputDiv input{
  padding: 1vh;
  font-size: 1em;
  margin-bottom: 1.5vh;
  color: #282525;
}

.labelDiv{
  margin-bottom: 1.5vh;
  color: #252B42;
  font-weight: 600;
}

input[type="file"]{
  display: none;
}

.customFileUpload {
  color: #fff;
  background-color: #003D6F;
  border: 0px;
  padding: 2vh;
  border-radius: 1vh;
  font-size: 1em;
  cursor: pointer;
}