/* Doctor Container */
.doctorContainer {
  margin: auto;
  margin-top: 10vh;
  width: 80%;
  max-width: 900px;
  background: #f0f8ff;
  padding: 3vh;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Doctor Card */
.doctorCard {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2vh;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Image and Info Flex Container */
.imageAndInfo {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #e6f7ff;
  padding: 2vh;
  border-radius: 8px;
}

/* Doctor Image */
.doctorImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #003d6f;
  object-fit: cover;
}

/* Doctor Details */
.doctorDetails {
  width: 100%;
}

.doctorDetails h1 {
  color: #003d6f;
  font-size: 1.8em;
  font-weight: 600;
  margin-bottom: 10px;
}

.doctorDetails h3 {
  margin: 0;
  font-size: 1.1em;
}

.doctorDetails h3 span {
  font-weight: normal;
  color: #555;
}

.doctorInfo {
  margin-top: 10px;
}

/* Doctor Info Items */
.infoItem {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 7.5px;
}

.icon {
  width: 20px;
  height: 20px;
  color: #003d6f;
}

/* Expertise List */
.expertiseList {
  list-style-type: none;
  padding: 0;
}

.expertiseList li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.checkIcon {
  color: #28a745;
}
 
/* Specialties Section */
.specialties {
  list-style-type: none;
  padding: 0;
}

.specialties li {
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
  color: #003d6f;
}

/* Success Stories List */
.successStories {
  list-style-type: none;
  padding: 0;
}

.successStories li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1em;
  margin-bottom: 10px;
}

/* Vision List */
.visionList {
  list-style-type: none;
  padding: 0;
}

.visionList li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1em;
  margin-bottom: 10px;
}


/* CTA Section */
.cta {
  margin-top: 20px;
  color: #28a745;
  font-size: 1.2em;
  text-align: center;
}

.cta span {
  font-weight: bold;
}

/* Change color of all h2 tags */
h2 {
  color: #003d6f;
}
