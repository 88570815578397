.appointments{
  width: 83%;
  height: 100%;
  overflow-y: scroll;
}

.noAppointments{
  margin-top: 20vh;
  text-align: center;
  font-size: 1.3em;
}

.appointmentHead{
  display: flex;
  margin-right: 2vh;
  margin-left: 2vh;
}

.date{
  margin-top: 2vh;
}

.statusTab{
  display: flex;
  width: 82vw;
  justify-content: end;
  gap: 1vh;
}

.statusTab span{
  border: 1px solid #082945;
  margin-top: 2vh;
  padding: 0.6vh 1vh;
  border-radius: 17px;
  margin-bottom: 2vh;
}

.statusTab span:hover{
  cursor: pointer;
}

.active{
  color: white;
  background-color: #1c588c;
}

.appointmentHeader{
  display: flex;
  justify-content: space-between;
  margin: 3vh 1vw;
  padding: 0 2vh;
  font-weight: 600;
}

.appointmentItem{
  display: flex;
  justify-content: space-between;
  margin: 1vw 1vw;
  border-radius: 10px;
  padding: 3.5vh 2vh;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.3)
}

.appointmentItemHover:hover{
  cursor: pointer;
  background-color: #1c588c;
  color: white;
}

.appointmentHeader div{
  width: 20%;
}

.appointmentItem div{
  width: 20%;
}

.download:hover {
  cursor: pointer;
}