.headingDiv{
  margin-top: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.headingDiv h2{
  color: #003D6F;
  font-size: 2em;
}

.faqList{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5vh;
}

.faqItem{
  width: 70%;
}

.answer{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}

.faqItem.openAns .answer {
  max-height: 300px; /* Adjust based on content */
  opacity: 1;
  padding: 2vh 0;
  margin-bottom: 1vh;
  font-weight: 600;
  border-left: 6px solid #003D6F;
  padding-left: 1vh;
}

.questionDiv .arrow {
  transition: transform 0.3s ease;
}

.faqItem.openAns .arrow {
  transform: rotate(180deg);
}

.questionDiv{
  display: flex;
  width: 100%;
}

.imageDiv{
  display: flex;
  align-items: center;
  background-color: #F6F8EA;
}

.imageDiv img{
  height: 70%;
  margin-right: 1vw;
}

.question{
  background-color: #F6F8EA;
  color: #003D6F;
  font-weight: bold;
  padding: 1vh;
  width: 90%;
}

.evenItem{
  background-color: #003D6F;
  color: #F6F8EA;
}

@media screen and (max-width: 900px) {
  .headingDiv{
    margin-top: 12vh;
  }
}