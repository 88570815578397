.bannerDiv{
  width: 100%;
  padding-top: 8vh;
}

.bannerDiv img{
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .bannerDiv{
    padding-top: 11vh;
  }
}