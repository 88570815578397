.dynamicInput {
  display: flex;
  flex-direction: column;
  margin-top: 2vh;
}

.dynamicInput label{
  margin-left: 1vh;
  font-size: 1.3em;
  font-weight: 600;
}

.dynamicInput input{
  margin-left: 1vh;
  width: 20vw;
  margin-top: 1vh;
  font-size: 1.1em;
  padding: 0.5vh;
}

.dynamicInput button{
  margin-left: 1vh;
  margin-top: 1vh;
  font-size: 1.1em;
  width: 10vw;
  padding: 0.5vh;
}