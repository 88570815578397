.inputBoxDiv{
  display: flex;
  flex-direction: row;
  margin: 2vh 2vh;
}

.multiSelectLabel {
  font-size: 1.2em;
  font-weight: bold;
  margin: 2vh 2vh;
}

.labelDiv{
  width: 50%;
}

.inputDiv {
  width: 50%;
}

.inputDiv input[type="text"], select{
  width: 100%;
  padding: 0.8vh;
  padding-right: 0vh;
  font-size: 1em;
}

.nestedInputBoxDiv{
  margin-top: 1vh;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.nestedLabelDiv{
  width: 20%;
  font-size: 0.9em;
}

.nestedInputDiv {
  width: 80%;
}

.heading {
  margin: 2vh 2vh;;
}

.submitDiv {
  margin: 4vh 0;
  display: flex;
  justify-content: center;
}