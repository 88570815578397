.contactUs{
  margin: auto;
  margin-top: 10vh;
  width: 90%;
  display: flex;
  gap: 2vw;
}

.dataDiv{
  width: 50%;
}

.mapDiv{
  width: 50%;
  margin-top: 1vh;
}

@media screen and (max-width: 800px) {
  .contactUs{
    flex-direction: column;
  }
  .dataDiv{
    width: 100%;
  }
  .mapDiv{
    width: 100%;
    margin-top: 0;
  }
}