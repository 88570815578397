.treatmentDiv h1{
  color: #003d6f;
  text-align: center;
}

 .treatmentDiv {
  margin: auto;
  margin-top: 12vh;
  width: 80vw;
  color: #333;
  font-family: 'Poppins', sans-serif;
}

.treatmentDiv p {
  line-height: 1.7em;
  font-size: 1em;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 3vh;
}

.card {
  border: 1px solid #e6e6e6;
  border-radius: 12px;
  padding: 16px;
  background: linear-gradient(145deg, #f6f8ea, #cce5ab);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card h3 {
  color: #003d6f;
  margin-bottom: 10px;
  font-size: 1.3em;
  text-align: center;
}

.card p {
  margin-bottom: 16px;
  font-size: 0.95em;
  color: #555;
  flex-grow: 1;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  border-width: 1mm;
  border-color: #003d6f;
}

.moreButton {
  display: block;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 600;
  color: #f6f8ea;
  background-color: #003d6f;
  border: none;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: auto;
  margin-bottom: 12px;
}

.moreButton:hover {
  background-color: #28a745;
  transform: translateY(-3px);
}

.treatmentItem {
  margin: auto;
  margin-top: 12vh;
  width: 75vw;
  font-size: 1.1em;
  margin-bottom: 5vh;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.watermarkContainer {
  position: relative;
}

.watermarkContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    var(--watermark);
  background-repeat: repeat;
  background-size: 10%;
  opacity: 0.03;
  /* transform: rotate(-25deg); */
  z-index: -1;
}

.treatmentItem h2 {
  color: #003d6f;
  font-size: 2em;
  margin-bottom: 20px;
  border-left: 5px solid #003d6f;
  padding-left: 10px;
}

.treatmentItem p {
  font-size: 1em;
  font-weight: 600;
  line-height: 1.7;
  color: #007BFF;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.treatmentItem ul {
  list-style: none;
  padding: 0;
}

.treatmentItem ul li {
  padding: 8px 0;
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 8px;
}

.treatmentItem ul li::before {
  content: '✔';
  font-size: 1.2em;
  color: #007BFF;
  font-weight: bold;
}

.severity-box {
  background: #eef5ff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.severity-box strong {
  color: #003d6f;
  font-size: 1.1em;
}

.benefits {
  background: linear-gradient(145deg, #f6f8ea, #cce5ab);
  padding: 20px;
  border-radius: 12px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.foods-box {
  background: #ffefef;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}