.editDiv{
  background-color: rgba(244, 200, 172, 0.722);
  width: 50%;
  margin: 1vh 0;
  padding: 1vh;
  border-radius: 5px;
  display: flex;
  position: relative;
}

.dotsDiv{
  margin-left: auto;
  cursor: pointer;
}

.popUp{
  position: absolute;
  right: 7%;
  background-color: #fff;
  padding: 0.7vh;
  border: 1px solid black;
  border-radius: 3px;
  font-size: 0.9em;
  cursor: pointer;
}