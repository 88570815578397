.homeBodyDiv{
  display: flex;
  margin: 3vh;
  gap: 3vw;
}

.whyUs{
  width: 40%;
  background-color: #F6F8EA;
  padding: 3vh;
  border-radius: 1vh;
}

.booking{
  width: 30%;
  background-color: #F6F8EA;
  padding: 3vh;
  border-radius: 1vh;
}

.tracking{
  width: 30%;
  background-color: #F6F8EA;
  padding: 3vh;
  border-radius: 1vh;
}

.title{
  font-weight: bold;
  font-size: 1.5em;
  color: #003D6F;
  margin-bottom: 1vw;
}

.whyUsItem{
  display: flex;
  margin: 1vh 0;
}

.whyUsItem div{
  margin-left: 1vw;
}

.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(158, 151, 151, 0.4);
  z-index: 20;
}

.modal, .modalTwo{
  padding: 0 2vw;
  position: fixed;
  top: 20vh;
  left: 25vw;
  width: 50vw;
  background-color: white;
  z-index: 30;
  border-radius: 15px;
  padding-bottom: 3vh;
}

.modalTwo{
  top: 30vh;
  left: 35vw;
  width: 30vw;
}

.modalHeading{
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  margin-top: 5vh;
}

.modalText{
  font-size: 1.2em;
  margin: 2vh 0;
  font-weight: 600;
  text-align: center;
}

.dateSelectDiv{
  display: flex;
  gap: 4vh;
}

.inputDate{
  width: 20%;
  font-size: 1.2em;
  padding: 1vh;
}

.timeSlotDiv{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* gap: 4vh; */
  justify-content: space-around;
}

.slotTime{
  padding: 2vh;
  border-radius: 6px;
  font-weight: bold;
  color: rgb(159, 215, 159);
  border: 2px solid rgb(126, 198, 126);
  margin-bottom: 1vh;
}

.available{
  cursor: pointer;
  color: green;
  border: 2px solid green;
}

.selectedSlot{
  background-color: green;
  color: white;
}

.confirmBtn{
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.errorMessage{
  color: red;;
  font-weight: bold;
  text-align: center;
  margin-top: 2vh;
  font-size: 1em;
}

.appointmentData{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4vh 0;
}

.smallText{
  font-weight: bold;
  font-size: 1.2em;
}

.closeBtn{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4vh;
}

.success{
  padding: 3vh;
  text-align: center;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .homeBodyDiv{
    display: flex;
    flex-direction: column;
  }

  .whyUs{
    width: 85%;
  }

  .booking{
    width: 85%;
  }

  .tracking{
    width: 85%;
  }
  .modal, .modalTwo{
    left: 5%;
    top: 10%;
    width: 85%;
    max-height: 80%;
    overflow-y: scroll;
  }
  .dateSelectDiv{
    justify-content: center;
  }
  .inputDate{
    width: 40%;
  }
}