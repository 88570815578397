.headingContainer{
  width: 100%;
  display: flex;
  justify-content: center;
}

.heading{
  color: #003D6F;
  font-size: 2em;
}

.testimonialDiv {
  display: flex;
  width: 70%;
  margin: auto;
  position: relative;
  z-index: 2;
}

.dataContainer {
  background-color: #003D6F;
  color: #fff;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 65%;
  height: 45vh;
}

.data {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
}

.name {
  margin-top: 1.5vh;
  width: 60%;
  color: #A2C109;
  font-weight: bold;
}

.textContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-left: 8vh;
}

.text {
  width: 60%;
  line-height: 2em;
  font-size: 1.2em;
}

.icons {
  font-size: 2em;
}

.rightQuote {
  width: 100%;
  display: flex;
  justify-content: end;
}

.imageDiv{
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 20%;
}

.imageDiv img{
  width: 70%;
}

.leftArrow{
  position: absolute;
  top: 45%;
  left: 2%;
  cursor: pointer;
}

.rightArrow{
  position: absolute;
  top: 45%;
  left: 93%;
  cursor: pointer;
}

@media screen and (max-width: 900px) {
  .testimonialDiv{
    display: flex;
    flex-direction: column;
    z-index: 2;
  }
  .text{
    font-size: 0.9em;
    line-height: 1.5em;
  }
  .dataContainer {
    width: 100%;
    height: 30vh;
  }
  .imageDiv{
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    left: 25%;
    bottom: -45%;
  }

  .arrowDiv{
    position: absolute;
    width: 100%;
    bottom: -10%;
  }

  .leftArrow{
    transform: rotate(180deg);
  }

  .leftQuote{
    position: absolute;
    top: 10%;
  }
  .data{
    width: 90%;
  }
  .imageDiv img{
    width: 60%;
  }
}

@media screen and (max-width: 600px) {

  .imageDiv{
    bottom: -35%;
  }

  .imageDiv img{
    width: 70%;
  }

  .arrowDiv{
    left: -2%;
  }

  .testimonialDiv{
    width: 85%;
  }
}